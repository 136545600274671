import React, { useEffect } from 'react';
import Head from 'next/head';
import { GetServerSidePropsContext } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import axios from '@DanbiEduCorp/axios';
import wrapper from '@/configs/reduxWrapper';
import SpinController from '@/library/spin/SpinController';
import APICaller, { APIMonitor } from '@/library/APICaller';
import APICache from '@/library/APICache';
import dynamic from 'next/dynamic';
import { useUserAgent } from 'next-useragent';

const LayerPopupWrapper = dynamic(() => import('@/wrapper/LayerPopupWrapper'), {
    ssr: false,
});
import '@/styles/tailwind.css';
import '@/styles/new_common.css';
import '@/styles/new_style.css';

import AdsScripts from '@/scripts';
import PageContainer from '@/container/PageContainer';
import NoSsrWrapper from '@/wrapper/NoSsrWrapper';
import { HOST_BY_ENV } from '@/constants/common';

export function getServerSideProps(context: GetServerSidePropsContext) {
    return {
        props: {
            uaString: context.req.headers['user-agent'],
        },
    };
}

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const ua = useUserAgent(
        pageProps?.uaString ?? typeof window === 'function'
            ? window?.navigator?.userAgent
            : ''
    );

    // API caller
    useEffect(() => {
        APIMonitor.defaults.healthCheckTime = 10000; // 10s
        APICaller.defaults.caching = false;
        APICaller.defaults.offlineMode = false;
        APICaller.defaults.debug = false;
        APICaller.defaults.timeout = 60000; // 5s
        APICache.initialize();
    }, []);

    axios.interceptors.request.use(
        SpinController.requestShowSpin,
        SpinController.requestError
    );
    axios.interceptors.response.use(
        SpinController.response,
        SpinController.responseError
    );

    useEffect(() => {
        if (
            !HOST_BY_ENV.client.local.test(window.location.hostname) &&
            window.location.protocol === 'http:'
        ) {
            window.location.protocol = 'https:';
        }

        if (!window.location.origin) {
            window.location.href =
                window.location.protocol +
                '//' +
                window.location.hostname +
                (window.location.port ? ':' + window.location.port : '');
        }
    }, []);

    useEffect(() => {
        if(window.location.origin.indexOf("https://wink.co.kr") > -1) {
            window.location.href = "https://www.wink.co.kr" + window.location.pathname + window.location.search;
        }
        if(window.location.origin.indexOf('https://danbi.biz') > -1) {
            window.location.href = "https://www.danbi.biz" + window.location.pathname + window.location.search;
        }
    }, []);

    // react-facebook-pixel
    useEffect(() => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('420194915299099');
                ReactPixel.pageView();

                router.events.on('routeChangeComplete', () => {
                    ReactPixel.pageView();
                });
            });
    }, [router.events]);

    useEffect(() => {
        if (ua?.isMobile) {
            if (
                window.location.hostname.toLowerCase().indexOf('wink.co.kr') !==
                -1
            ) {
                window.location.href = `https://m.wink.co.kr/${window.location.search}`;
            } else {
                window.location.href = `https://m.danbi.biz/${window.location.search}`;
            }
            return;
        }

        if (ua.isIE) {
            if (Math.floor(ua.browserVersion / 10) <= 9) {
                alert(
                    '윙크 홈페이지는 안정화된 서비스 제공을 위하여,\r\n구 버전 브라우저(IE 9이하)에서는 동작하지 않습니다.\r\n\r\n브라우저 업데이트(IE 10 이상)를 진행하시거나,\r\nChrome 브라우저를 이용해주세요.'
                );
                window.location.href =
                    'https://www.google.com/chrome/browser/desktop/index.html';
            }
        }
    }, [ua]);

    return (
        <>
            <Head>
                {/* viewport meta tag should not be use in _document.js */}
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <meta name="viewport" content="width=1280" />
                <title>WINK - 가장 많이 선택하는 유초등 1위학습</title>
            </Head>
            <NoSsrWrapper>
                <AdsScripts router={router} />
            </NoSsrWrapper>

            <PageContainer history={router}>
                <Component {...pageProps} history={router} />
            </PageContainer>
            <LayerPopupWrapper history={router} />
        </>
    );
}

export default wrapper.withRedux(MyApp);
