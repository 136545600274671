import Script from 'next/script';
import { useEffect, useState } from 'react';

const KakaoDaum = () => {
    return (
        <>
            <Script
                async
                src="//t1.daumcdn.net/adfit/static/kp.js"
                onLoad={() => {
                    if (typeof window.kakaoPixel === 'function') {
                        kakaoPixel('6818168787396902769').pageView();
                    }
                }}
            ></Script>
        </>
    );
};

export default KakaoDaum;
