import Script from 'next/script';
import { useEffect, useState } from 'react';

const Naver = () => {
    return (
        <>
            <Script
                async
                src="//wcs.naver.net/wcslog.js"
                onLoad={() => {
                    if (!wcs_add) var wcs_add = {};
                    wcs_add['wa'] = 's_256d4a7e2b6e';
                    if (!_nasa) var _nasa = {};
                    if (window.wcs) {
                        wcs.inflow('wink.co.kr');
                        wcs_do(_nasa);
                    }
                }}
            ></Script>
        </>
    );
};

export default Naver;
