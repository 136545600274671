import Script from 'next/script';
import { useEffect } from 'react';
export const Enliple = ({ router }) => {
    useEffect(() => {
        const runEnlipleScript = () => {
            if (typeof window !== 'undefined') {
                window.CallMtm =
                    window.CallMtm ||
                    function () {
                        (window.CallMtm.q = window.CallMtm.q || []).push(
                            arguments
                        );
                    };
                CallMtm();
            }
        };
        runEnlipleScript();
        router.events.on('routeChangeComplete', runEnlipleScript);

        return () => {
            router.events.off('routeChangeComplete', runEnlipleScript);
        };
    }, [router.events]);
    return (
        <>
            <Script
                async
                src="https://cdn.onetag.co.kr/0/tcs.js?eid=1iukwdlhse8v91iukwdlhs"
                // onLoad={() => {
                //     if (typeof window !== 'undefined') {
                //         window.CallMtm =
                //             window.CallMtm ||
                //             function () {
                //                 (window.CallMtm.q =
                //                     window.CallMtm.q || []).push(arguments);
                //             };
                //         CallMtm();
                //     }
                // }}
            ></Script>
        </>
    );
};
