import { GoogleTagManager } from './GoogleTagManager';
import { GaScript } from './GaScript';
import { Enliple } from './Enliple';
import { AceCounter } from './AceCounter';
import { ADN } from './ADN';
import KakaoDaum from './KakaoDaum';
import Naver from './Naver';
import Script from 'next/script';

const AdsScripts = ({router}) => {
    return (
        <>
            <Script
                async
                integrity="sha384-dpu02ieKC6NUeKFoGMOKz6102CLEWi9+5RQjWSV0ikYSFFd8M3Wp2reIcquJOemx"
                crossOrigin="anonymous"
                src="https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js"
                onLoad={() => {
                    if (window.Kakao) {
                        Kakao.init('f90ebd57e0eec6f05ffa382e34c97a21');
                    }
                }}
            ></Script>
            <Script
                async
                src="https://xpay.tosspayments.com/xpay/js/xpay_crossplatform.js"
            ></Script>

            {/* <Script
                dangerouslySetInnerHTML={{
                    __html: '!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load(\'CONFOA3C77U6GGR45ST0\'); ttq.page(); }(window, document, \'ttq\');',
                }}
            ></Script> */}
            <GoogleTagManager />
            <AceCounter />
            <ADN />
            <KakaoDaum />
            <GaScript />
            <Enliple router={router}/>
            <Naver />
        </>
    );
};

export default AdsScripts;
